import { useAppSelector } from "../../store"

// This returns a function to check if a feature flag is currently active.
const useFeatureFlags = () => {
  const flags = useAppSelector((state) => state.session.features?.flags) ?? []
  const hasFlag = (flag: string) => {
    return flags.includes(flag)
  }
  return { hasFlag, flags }
}

export default useFeatureFlags
