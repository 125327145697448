import { Features } from "./types/features.type"

const features: Features = {
  config: {
    description: "Testing configuration panel",
    filters: [
      {
        env: ["test", "development"]
      }
    ]
  },
  ["onboarding.access.mobile"]: {
    description: "Assign access step in the add cardholder wizard (mobile)",
    filters: [{ env: ["development"] }]
  },
  ["onboarding.pdflist.mobile"]: {
    description: "Addtional details step in the add cardholder wizard (mobile)",
    filters: [{ env: ["development"] }]
  },
  ["onboarding.credentials.mobile"]: {
    description: "Issue credentials step in the add cardholder wizard (mobile)",
    filters: [{ env: ["development"] }]
  },
  ["onboarding.competencies.desktop"]: {
    description: "Assign competencies step in the add cardholder wizard (desktop)",
    filters: [{ env: [] }]
  },
  ["onboarding.competencies.mobile"]: {
    description: "Assign competencies step in the add cardholder wizard (mobile)",
    filters: [{ env: [] }]
  },
  ["onboarding.summary.mobile"]: {
    description: "Summary step in the add cardholder wizard (mobile)",
    filters: [{ env: ["development"] }]
  },
  darkMode: {
    description: "Switch UI theme to dark mode",
    isEnabled: false
  },
  settings: {
    description: "Settings page",
    filters: [{ env: ["development", "test", "production"] }]
  },
  simpleSearch: {
    description: "Simple Search",
    filters: [{ env: ["development", "test", "production"] }]
  }
}

export default features
