import React, { useRef, useState } from "react"
// helpers
import { ApiError } from "../../common/utils/apiCall"
import { captureTelemetry } from "../../common/utils/postHog"
import invalidateSession from "../../store/invalidateSession"
import { changePassword } from "../ChangePassword/apicalls"
// hooks
import { useRouter } from "next/router"
import useFeatureFlags from "../../common/utils/useFeatureFlags"
// components
import { Avatar, Button, ContextMenu, HamburgerButton, ListItem } from "@ggl/components"
import ChangePasswordDialog from "../ChangePassword"
import Feedback from "../Feedback/Feedback"
// state management
import { selectCommandCentreVersion, selectCurrentSessionOperatorInfo, selectDrawerClosed, selectTimestamp, setDrawerClosed, useAppDispatch, useAppSelector } from "../../store"
// constants
import Version, { CC_WEB_VERSION, ENV } from "../../common/utils/version"
import { NOTES_URL } from "../NewFeatures/Content"
// CSS
import styles from "./header.module.scss"

const Labels = {
  PrivacyPolicy: "Privacy Policy",
  Feedback: "Feedback",
  TapProfile: "Tap your profile for more information.",
  ChangePassword: "Change password",
  AvatarDesc: "Access to your profile information.",
  SignOut: "Sign out",
  ApiError: "Internal Error: Cannot change password, missing sessionInfo",
  Features: "Feature flags",
  WhatsNew: "What's New",
  TermsOfUse: "Terms of Use",
  Settings: "Settings",
  Back: "Back"
} as const

/**
 * The returns the main header bar for the application
 * It also includes functionality for user profile drop down menu
 * This can also bring up the change password dialog as a child component
 * @returns <Header />
 */
//URLS
const PRIVACY_URL = "https://security.gallagher.com/Legal/Command-Centre-Web-Privacy-policy"
const TERMS_OF_USE_URL = "https://security.gallagher.com/Legal/Command-Centre-Web-Subscription-Terms"

const Header: React.FC = () => {
  //hooks
  const router = useRouter()
  const { hasFlag } = useFeatureFlags()
  // redux
  const operatorInfo = useAppSelector(selectCurrentSessionOperatorInfo)
  const drawerClosed = useAppSelector(selectDrawerClosed)
  const timestamp = useAppSelector(selectTimestamp)
  const ccVersion = useAppSelector(selectCommandCentreVersion)
  const dispatch = useAppDispatch()

  const thumbnail = operatorInfo?.thumbnail?.href.concat("&", timestamp) //using timestamp to force browser refresh the image
  // ui state
  const profileDiv = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)

  const showTermsOfUse = Version.greaterThanOrEqual(ccVersion, "9.10.0") && !hasFlag("settings")
  const hideBackButton = router?.pathname === "/"

  const doUpdatePassword = async (password: string, newPassword: string): Promise<boolean> => {
    const updatePasswordHref = operatorInfo?.updatePassword?.href
    if (updatePasswordHref) {
      // this will throw an ApiError if the server returns a 4xx or other error message
      await changePassword(updatePasswordHref, password, newPassword)
      return true
    }
    // this should never happen
    throw new ApiError(-1, Labels.ApiError)
  }

  const avatarClickHandler = (e: React.MouseEvent) => {
    captureTelemetry("Clicked Profile Menu", { groupType: "CC_Web_Version", groupKey: `${CC_WEB_VERSION}` })
    setShowMenu(!showMenu)
  }

  const signOutHandler = () => {
    setShowMenu(!showMenu)
    captureTelemetry("Clicked (Sign out)", { groupType: "CC_Web_Version", groupKey: `${CC_WEB_VERSION}` })
    invalidateSession()
  }

  const menuCloseHandler = () => {
    setShowMenu(false)
  }

  const toggleHandler = () => {
    dispatch(setDrawerClosed(!drawerClosed))
  }

  const openFeedback = () => {
    setShowMenu(false)
    setShowFeedback(true)
  }

  const renderVersions = () => {
    if (window.ENV === "production") return <ListItem text={[CC_WEB_VERSION ? `CC Web: ${CC_WEB_VERSION}` : "", ccVersion ? `CC: ${ccVersion}` : ""].filter((v) => v).join(" - ")} disabled />

    return (
      <>
        <ListItem text={`CC: ${ccVersion}`} disabled />
        <ListItem text={`CC Web: ${CC_WEB_VERSION}`} disabled />
      </>
    )
  }

  const renderMenu = () => {
    let height = 260
    height += 40 // What's new
    if (hasFlag("config")) height += 40
    if (showTermsOfUse) height += 40
    if (hasFlag("settings")) height += 40
    if (!hasFlag("settings")) height += 40 * 2
    if (ENV !== "production" && CC_WEB_VERSION) height += 40

    return (
      <ContextMenu className={styles.contextMenu} closeHandler={menuCloseHandler} dimensions={{ right: 16, top: 48, height }}>
        <div className={styles.menu}>
          <Avatar firstName={operatorInfo?.firstName} lastName={operatorInfo?.lastName} size="large" src={thumbnail} onClick={avatarClickHandler} />
          <div className={styles.details}>
            <p className={styles.name}>
              {operatorInfo?.firstName} {operatorInfo?.lastName}
            </p>
          </div>
        </div>
        <ul id="profile_menu">
          <ListItem id="feedback" text={Labels.Feedback} onClick={openFeedback} />
          <ListItem
            text={Labels.WhatsNew}
            onClick={() => {
              window.open(NOTES_URL)
              captureTelemetry("Clicked (Whats New)")
            }}
          />
          {operatorInfo?.updatePassword && (
            <ListItem
              id="changepassword_menu_item"
              text={Labels.ChangePassword}
              onClick={() => {
                setShowChangePasswordModal(true)
                setShowMenu(false)
                captureTelemetry("Clicked (Change Password)")
              }}
            />
          )}
          {hasFlag("config") && (
            <ListItem
              text={Labels.Features}
              onClick={() => {
                setShowMenu(false)
                router.push("/debug")
              }}
            />
          )}
          {!hasFlag("settings") && (
            <ListItem
              id="privacy_policy"
              text={Labels.PrivacyPolicy}
              onClick={() => {
                window.open(PRIVACY_URL)
                captureTelemetry("Clicked (Privacy Policy)")
              }}
            />
          )}
          {showTermsOfUse && (
            <ListItem
              id="terms_of_use"
              text={Labels.TermsOfUse}
              onClick={() => {
                window.open(TERMS_OF_USE_URL)
                captureTelemetry("Clicked (Terms of Use)")
              }}
            />
          )}
          {hasFlag("settings") && (
            <ListItem
              id="settings"
              text={Labels.Settings}
              onClick={() => {
                setShowMenu(false)
                router.push("/settings")
                captureTelemetry("Clicked (Settings)")
              }}
            />
          )}
          <ListItem id="signout_menu_item" text={Labels.SignOut} onClick={signOutHandler} />
          {!hasFlag("settings") && renderVersions()}
        </ul>
      </ContextMenu>
    )
  }

  const cssClasses = [styles.profile, showMenu ? styles.active : undefined]

  return (
    <>
      <header className={styles.root}>
        <div className={styles.hamburger}>{operatorInfo && <HamburgerButton isOpen={!drawerClosed} onClick={toggleHandler} id="hamburger" />}</div>
        {!hideBackButton && (
          <Button
            leftIcon="back-circle"
            color="none"
            text={Labels.Back}
            className={styles.headerBack}
            variant="no-border"
            onClick={() => {
              router.back()
              captureTelemetry("Clicked (Header Back) Button")
            }}
          />
        )}
        <div id="autosave-indicator-portal" className={styles.autosaveIndicatorPortal}></div>
        <Button variant="no-border" color="primary" className={styles.feedbackBtn} onClick={openFeedback}>
          {Labels.Feedback}
        </Button>
        <div ref={profileDiv} className={cssClasses.join(" ").trim()} aria-label={Labels.AvatarDesc} onClick={avatarClickHandler}>
          {operatorInfo && <Avatar id="profile_avatar" firstName={operatorInfo.firstName} lastName={operatorInfo.lastName} src={thumbnail} size="small" onClick={avatarClickHandler} />}
        </div>
      </header>
      {showMenu && renderMenu()}
      {showFeedback && <Feedback onClose={() => setShowFeedback(false)} />}
      {showChangePasswordModal && operatorInfo?.updatePassword && (
        <ChangePasswordDialog closeHandler={() => setShowChangePasswordModal(false)} passwordRestrictions={operatorInfo.updatePassword.restrictions} updatePasswordHandler={doUpdatePassword} />
      )}
    </>
  )
}

export default Header
