import { useEffect, useState } from "react"
import Banner from "./Banner"
import EarlyAccessBanner from "./EarlyAccessBanner"
import styles from "./main.module.scss"

const Main = ({ children }: { children?: React.ReactNode }) => {
  const [extraCss, setExtraCss] = useState<string>("")

  useEffect(() => {
    const observer = new MutationObserver((mutationList) => {
      mutationList
        .filter((mutation) => mutation.type === "childList")
        .forEach(() => {
          const breadcrumbElement = document.querySelector("#breadcrumb-portal")
          setExtraCss(breadcrumbElement?.hasChildNodes() ? styles.hasBreadcrumb : "")
        })
    })
    observer.observe(document.querySelector("#breadcrumb-portal")!, { childList: true })

    return () => observer.disconnect()
  }, [])

  return (
    <section className={`${styles.scroll} ${extraCss}`.trim()}>
      <EarlyAccessBanner />
      <Banner />
      <main className={styles.main} role="main">
        {children}
      </main>
    </section>
  )
}

export default Main
