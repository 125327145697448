import HttpMethod from "../types/HttpMethod.type"
// simple validation to make sure that the client key provided as a subdomain looks like a hex client key
export function isValidHexClientKey(key: string) {
  // client keys 16 bytes, 2 chars per byte
  // URL subdomain is forced to be lowercase
  const regex = /[0-9a-f]{32}/g
  return regex.test(key)
}

export function buildHeaders(hasPayload: boolean) {
  const headers = new Headers()
  if (hasPayload) {
    headers.set("content-type", "application/json")
  }

  const hostNameArray = window.location.hostname.split(".")

  // Setting the Authorization header is done in this order:
  // a. If provided as a subdomain (this will work for production & local dev)
  // b. If provided in env_vars.js (this will work for localhost dev or automation)
  // otherwise, no key is set
  const clientKey = hostNameArray.length > 1 ? hostNameArray[0] : null

  if (clientKey && isValidHexClientKey(clientKey)) {
    headers.set("Authorization", `GGL-WEB-CLIENT ${clientKey}`)
  } else if (window.location.hostname == "localhost" && window.GatewayAutomationWebClientKey) {
    headers.set("Authorization", `GGL-WEB-CLIENT ${window.GatewayAutomationWebClientKey}`)
  }
  return headers
}

export function buildRequest(method: HttpMethod, hasPayload: boolean, controller?: AbortController) {
  return {
    signal: controller?.signal,
    cache: "no-cache" as RequestCache,
    method: method,
    credentials: "include" as RequestCredentials,
    headers: buildHeaders(hasPayload ? true : false)
  } as any // we need to return as any so we can append the body later if desired
}

// just some intellisense support for server sent errors
export interface JsonError {
  code?: number
  message?: string
  info?: string
}

export class ApiError extends Error {
  // carries the HTTP status code of the response
  httpStatusCode: number;
  // carries other arbitrary properties that might have been sent from the server, like 'code', etc
  [key: string]: any

  constructor(httpStatusCode: number, message?: string, code?: number) {
    super(message)
    this.httpStatusCode = httpStatusCode
    if (code) {
      this["code"] = code
    }
  }
}

// a generic handler for making JSON calls to the server
// this will throw an ApiError if the server returns an error code, optionally including any JSON the server sent back
export async function apiCall<T>(url: string, method: HttpMethod, payload?: any, controller?: AbortController): Promise<T> {
  // To abort currently active requests simply use controller.abort()
  if (!url) {
    throw new ApiError(400, "URL not supplied.")
  }
  const req = buildRequest(method, payload ? true : false, controller)
  if (payload) req.body = JSON.stringify(payload)
  try {
    const res = await fetch(url, req)
    if (res.ok) {
      if (res.headers.get("content-type")?.includes("application/json")) {
        const json = await res.json()
        return json as T
      } else if (res.status == 204) {
        // no content, e.g. from patch or delete
        return undefined! // if a caller is expecting the server might return a 204, they should be using ApiCall<SomeObject?> and thus can handle the null
      } else {
        throw new ApiError(415, "Unexpected content Type.")
      }
    } else {
      // errors can carry JSON response payloads, pick those up
      const json = res.headers.get("content-type")?.includes("application/json") ? await res.json().catch((_) => undefined) : undefined
      if (json) {
        if ("message" in json) {
          const apiError = new ApiError(res.status, json.message)
          Object.assign(apiError, json) // add any other properties the server sent us into the error object
          throw apiError
        } else {
          const apiError = new ApiError(res.status) // DON'T put a message here, so that callers can detect this and insert their own context-specific messages
          Object.assign(apiError, json) // add any other properties the server sent us into the error object
          throw apiError
        }
      }
      // no content, all we have is the HTTP status code
      throw new ApiError(res.status) // DON'T put a message here, so that callers can detect this and insert their own context-specific messages
    }
  } catch (err: unknown) {
    if (err instanceof ApiError) {
      // 401s aren't valid responses we should receive from the API, this implies that our session has expired
      // we should do a check here and log the user out, or inform them the session has expired
      if (err.httpStatusCode === 401) console.warn("Session may have invalidated unexpectedly.")
      throw err
    }
    if (err instanceof DOMException) throw err
    throw new ApiError(400, `General error. ${(err as Error)?.message ?? ""}`)
  }
}

// heres a collection of helper decorator
export async function get<T>(url: string, controller?: AbortController) {
  return apiCall<T>(url, "GET", undefined, controller)
}

export async function post<T>(url: string, payload: any) {
  return apiCall<T>(url, "POST", payload)
}

export async function patch<T>(url: string, payload: any) {
  return apiCall<T>(url, "PATCH", payload)
}

export async function del<T>(url: string) {
  return apiCall<T>(url, "DELETE")
}
