import { Card, Chip, HasChildren } from "@ggl/components"
import styles from "./newFeatures.module.css"

/*
 * To update new features:
 *  1. Bump RELEASE_ID
 *  2. Update <Content /> with new features, updates, and bug fixes.
 */

// the is the identifier for this release, this is used to track whether the pop has been viewed
export const RELEASE_ID = "0.0.7"

// This is the title for the pop up
export const TITLE = "New Features"

// This is the button text for the pop up
export const BTN_LABEL = "Open Release Notes"

// This is the URL that contains the full release information
export const NOTES_URL = "https://security.gallagher.com/Command-Centre-Web-Release-Notes"

const NEW_TITLE = "New"
const UPDATES_TITLE = "Updates"
const FIXED_BUGS_TITLE = "Bug Fixes"

const New = ({ children }: HasChildren) => {
  return (
    children && (
      <>
        <Chip name={NEW_TITLE} type="message" color="success" size="small" className={styles.chip} />
        <br />
        {children}
      </>
    )
  )
}

const Updates = ({ children }: HasChildren) => {
  return (
    children && (
      <>
        <Chip name={UPDATES_TITLE} type="message" color="primary" size="small" className={styles.chip} />
        <br />
        {children}
      </>
    )
  )
}

const BugFixes = ({ children }: HasChildren) => {
  return (
    children && (
      <>
        <Chip name={FIXED_BUGS_TITLE} type="message" color="destructive" size="small" className={styles.chip} />
        <br />
        {children}
      </>
    )
  )
}

/** This function is broken out to make it easier to edit the contents of the pop up without having to wade through other functionality */
export const Content = () => {
  return (
    <Card className={styles.card}>
      <New>
        <strong>Enhanced Cardholder Search! 🔍</strong>
        <ul className={styles.list}>
          <li>Customise how your search results display</li>
          <li>Choose which fields to search by</li>
          <li>Quickly locate cardholders by searching card numbers, PDF values, and more</li>
        </ul>
        This allows you to tailor the search experience to the specific needs of your site.
        <br />
        <br />
        Want to know more? Check out the release notes.
      </New>

      <Updates></Updates>

      <BugFixes></BugFixes>
    </Card>
  )
}
