// redux
import { Action, configureStore, PreloadedState, ThunkAction } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "./storage"
// reducers for each of our features
import { setupListeners } from "@reduxjs/toolkit/query"
import cardholderSearchSliceOld from "../components/CardholderSearch/cardholderSearch.slice"
import cardholderSimpleSearchSlice, { cardholderSearchSlice } from "../components/CardholderSearch/cardholderSimpleSearch.slice"
import { api } from "./api.slice"
import personSlice from "./person.slice"
import sessionReducer, { setFeature } from "./session.slice"

// combine all reducers from each of our product features together
const appReducer = combineReducers({
  session: sessionReducer,
  cardholderSearchOld: cardholderSearchSliceOld,
  cardholderSearch: cardholderSimpleSearchSlice,
  person: personSlice,
  [api.reducerPath]: api.reducer
})

// This is the key which will be saved into local storage
const persistConfig = {
  key: "root",
  storage,
  blacklist: [api.reducerPath, cardholderSearchSlice.name]
}

// this root reducer will remove all user data
const rootReducer = (state: any, action: any) => {
  if (action.type === "END_SESSION") {
    storage.removeItem(`persist:${persistConfig.key}`)
    // we could set initial states, or preserve items but its unnecessary at this time
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

// This is the wrapper which handles pulling and pushing data to local storage
const persistedReducer = persistReducer(persistConfig, rootReducer)

// This allows us to inject a state for testing purposes
export const setupStore = (preloadedState?: PreloadedState<Partial<RootState>>) => {
  return configureStore({
    reducer: persistedReducer,
    devTools: process.env.ENV !== "production",
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
    preloadedState
  })
}

// This is just to make calling the store programmatically alittle less weighty, just import {store}
export const store = setupStore()
setupListeners(store.dispatch)

if (typeof window !== "undefined") {
  window.setFlag = (flag: string, state: boolean) => {
    store.dispatch(setFeature({ name: flag, state: state }))
  }
}

export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
